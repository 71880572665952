const Productdb =[
    {
        id:1,
        image :"../city samosa only dish 2.webp",
        span1:"Bite",
        span2:"into joy with",
        span3:"City Samosa!",  
        Productnu:"Product 1 / 9"    
    },
    {
        id:2,
        image :"../vada pav 1 1.webp",
        span1:"Feel",
        span2:"the spice in every",
        span3:"Vada Pav bite!",  
        Productnu:"Product 2 / 9"          
    },
    {
        id:3,
        image :"../pattice pakoda 1 1.webp",
       span1:"Crisp",
       span2:"outside, spicy inside",
       span3:"Bread Pattis!",
       Productnu:"Product 3 / 9" 
    },
    {
        id:4,
        image :"../ulta vada pav 1 1.webp",
        span1:"Ulta Vada Pav",
        span2:"a twist of ",
        span3:"flavors!",
        Productnu:"Product 4 / 9" 
    },
    {
        id:5,
        image :"../crispy vada pav 3 3.webp",
         span1:"Crispy Vada Pav",
         span2:"crunch into ",
         span3:"happiness!",
         Productnu:"Product 5 /  9" 
    },   
]
export default Productdb;